html {
  min-height: 100%;
  font-size: 16px;
}
@media screen and (min-width: 600px) {
  html {
    font-size: 20px;
 }
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #333;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  line-height: 1.4;
}
ol {
  line-height: 1.4;
}
li {
  margin-bottom: 0.5rem;
}
strong {
  font-weight: 700;
}
a {
  color: #005596;
}
a:hover {
  color: #002c4e;
}
img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.site-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .site-header {
    flex-direction: row;
    align-items: center;
 }
}
.site-header__link {
  text-decoration: none;
}
.site-header__title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main {
  margin: 100px auto 0;
}
.footer {
  padding: 32px 16px;
  display: flex;
  justify-content: center;
}
.footer span {
  padding: 0 16px;
}
.article__header {
  padding: 32px 0;
}
.article__header--startpage {
  background-color: #eee;
  background-size: cover;
  background-position: 50% 70%;
  background-repeat: no-repeat;
  position: relative;
}
.article__header--startpage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.article__header h1 {
  text-align: center;
  max-width: 900px;
  font-size: 34px;
  line-height: 1.2;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}
@media screen and (min-width: 600px) {
  .article__header h1 {
    font-size: 54px;
 }
}
.article__header h2 {
  margin: 0;
  color: #555;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.5rem;
}
.article__header__image {
  margin-top: 40px;
  max-height: 360px;
  object-fit: cover;
  object-position: center;
  max-width: 1000px;
}
.article__section {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 16px 60px;
}
.article__section--wide {
  max-width: 1000px;
}
.article__section p.preamble {
  font-size: 1.4rem;
  line-height: 1.2;
}
.section-w-text {
  max-width: 700px;
  margin: 50px auto;
  padding: 0 16px;
}
@media screen and (min-width: 600px) {
  .section-w-text {
    margin: 100px auto;
 }
}
.section-w-text-image, .section-w-image-text {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  padding: 0 16px;
}
@media screen and (min-width: 600px) {
  .section-w-text-image, .section-w-image-text {
    margin: 100px auto;
    flex-direction: row;
 }
}
@media screen and (min-width: 600px) {
  .section-w-text-image .section-image {
    margin-left: 50px;
 }
}
@media screen and (min-width: 600px) {
  .section-w-image-text {
    flex-direction: row-reverse;
 }
}
@media screen and (min-width: 600px) {
  .section-w-image-text .section-text {
    margin-left: 50px;
 }
}
.section--first {
  margin-top: 30px;
}
@media screen and (min-width: 600px) {
  .section--first {
    margin-top: 50px;
 }
}
.section-text {
  flex: 1;
}
.section-image {
  flex: 1;
  text-align: center;
}
.section-image img {
  max-width: 320px;
}
.section-image figcaption {
  max-width: 320px;
  font-size: 14px;
  text-align: left;
  margin: 10px auto;
}
.table {
  background-color: rgba(255, 255, 255, 1);
  border-spacing: 0;
  margin-bottom: 40px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .table {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
 }
}
.table__head td {
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 4px solid rgba(64, 64, 64, 1);
  font-weight: 700;
}
.table__body tr:nth-child(odd) {
  background-color: rgba(242, 242, 242, 1);
}
.table__body td:first-child {
  font-weight: 500;
}
@media only screen and (min-width: 600px) {
  .table--center-2 td:nth-child(2) {
    text-align: center;
 }
}
td {
  padding: 8px;
  border-left: 1px solid rgba(217, 217, 217, 1);
  border-top: 1px solid rgba(217, 217, 217, 1);
}
@media only screen and (max-width: 400px) {
  td {
    font-size: 14px;
 }
}
@media only screen and (min-width: 600px) {
  td:first-child {
    border-left: 0;
 }
}
@media only screen and (min-width: 600px) {
  tr:first-child td {
    border-top: 0;
 }
}
@media only screen and (max-width: 599px) {
  table, thead, tbody, th, td, tr {
    display: block;
 }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
 }
  tr {
    border-bottom: 16px solid #fff;
 }
  td {
    position: relative;
    padding-left: 50%;
    border-right: 1px solid rgba(217, 217, 217, 1);
 }
  td:first-child {
    font-weight: 700;
 }
  td:last-child {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
 }
  td:before {
    position: absolute;
    top: 7px;
    left: 6px;
    width: 45%;
    white-space: nowrap;
 }
  .table td:nth-of-type(1):before {
    content: 'Leverantör';
 }
  .table td:nth-of-type(2):before {
    content: 'Webbaserade portallösning';
 }
  .table td:nth-of-type(3):before {
    content: 'Förmånsutbud';
 }
  .table td:nth-of-type(4):before {
    content: 'Bra att veta';
 }
}


@media screen and (min-width: 767px) {
  .menu {
    position: relative;
    z-index: 99;
 }
}
.menu .active {
  text-decoration: none;
  color: #333;
  pointer-events: none;
  font-weight: 700;
}
@media screen and (min-width: 767px) {
  .menu .active {
    font-weight: 400;
 }
}
.menu__toggle {
  -webkit-appearance: none;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid #666;
  border-radius: 3px;
  background-color: #fff;
  z-index: 9;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .menu__toggle {
    display: none;
 }
}
.menu__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
  z-index: 8;
  font-size: 16px;
  font-weight: 500;
}
.menu__list--open {
  transform: translateY(0);
}
@media screen and (min-width: 767px) {
  .menu__list {
    flex-direction: row;
    position: relative;
    transform: translateY(0);
 }
}
.menu__list li {
  margin-bottom: 0;
}
.menu__list a {
  color: #333;
  text-decoration: none;
}
.menu__list__item {
  position: relative;
  padding: 0 8px;
  margin: 0;
}
.menu__list__item:hover .menu__foldout {
  display: block;
}
.menu__list__link {
  position: relative;
  display: block;
  padding: 8px;
}
@media screen and (min-width: 767px) {
  .menu__list__link {
    padding: 16px 8px;
 }
}
@media screen and (min-width: 767px) {
  .menu__list__link.active::before {
    content: '';
    position: absolute;
    bottom: 8px;
    height: 2px;
    left: 8px;
    right: 8px;
    background-color: #777;
 }
}
